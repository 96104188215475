










































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { IEventUpdate } from "@/interfaces";
import { dispatchGetEvent, dispatchUpdateEvent, dispatchGetHandlers, dispatchDeleteEvent, dispatchDeleteHandler } from "@/store/main/actions";
import { readEvent, readHandlers } from '@/store/main/getters';

@Component
export default class Edit extends Vue {
  public loading = true;
  public valid = false;
  public dialog = false;
  public handlerDialog = false;
  public name = '';
  public id = 0;

  public pagination = {
    ascending: true,
    sortBy: 'name',
    rowsPerPage: 25,
    page: 1,
  };

  public headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Type',
      sortable: true,
      value: 'type',
      align: 'left',
    },
    {
      text: 'Params 1',
      sortable: true,
      value: 'params_1',
      align: 'left',
    },
  ];

  public event: IEventUpdate = {
    name: "",
    type: "",
    active_handler_id: undefined,
  };

  public reset() {
    this.event = <IEventUpdate>{ ...this.getEvent };
    this.$validator.reset();
  }

  get getEvent() {
    return readEvent(this.$store);
  }

  get getHandlers() {
    return readHandlers(this.$store);
  }

  public cancel() {
    this.$router.back();
  }

  get eventId() {
    return Number(this.$router.currentRoute.params.id);
  }

  public async mounted() {
    await dispatchGetEvent(this.$store, this.eventId);
    this.event = <IEventUpdate>{ ...readEvent(this.$store) };
    if (this.getEvent) {
      await dispatchGetHandlers(this.$store, this.eventId);
    }
    this.loading = false;
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      if(!this.event.active_handler_id){
        this.event.active_handler_id = null;
      }
      await dispatchUpdateEvent(this.$store, {eventId: this.eventId, data: this.event});
      this.$router.back();
    }
  }

  public async confirmDeleteEvent() {
    await dispatchDeleteEvent(this.$store, this.eventId);
    this.$router.back();
  }

  public async confirmDeleteHandler() {
    this.handlerDialog = false;
    await dispatchDeleteHandler(this.$store, this.id);
    await dispatchGetHandlers(this.$store, this.eventId);
  }
}
